<template>
  <div>

    <!-- Main Row -->
    <div class="row">

      <!-- Form -->
      <div class="col-md-12">
        <Card v-bind:title="'Edit Resep Obat'">
          <template
            v-slot:body
            v-if="dataLoaded"
          >
            <Form
              :form="form"
              :route="'prescriptions/' + form.id"
            />
          </template>
        </Card>
      </div>

    </div>

  </div>
</template>

<script>

import Card from "@/view/content/Card.vue"
import Form from "@/component/prescriptions/FormEdit.vue";
import module from '@/core/modules/CrudModule.js'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  components: {
    Card,
    Form
  },

  data() {
    return {
      // Form
      form: {
        date: null,
        patient_id: null,
        patient_name: "",
        doctor_id: '',
        doctor_name: "",
        history: "",
        notes: "",
        advice: "",
        medicine: "",
        redeem_type:"",
      },
      // Other
      dataLoaded: false
    }
  },

  methods: {

    async get() {
      this.form = await module.get('prescriptions/' + this.$route.params.id)
      // If Data Not Found
      if (this.form == null) {
        // Redirect To List
        this.$router.push('/masters/prescriptions/list')
      } else {
        this.form['_method'] = 'put'
        this.dataLoaded = true
      }
    }

  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Resep Obat", route: "" },
      { title: "Daftar Resep Obat", route: "/masters/prescriptions/list" },
      { title: "Tambah" },
    ])
    // Get Data
    this.get()
  },

}

</script>